/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */

.three-columns-with-cta {
    .column {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - #{$spacing / 2});
        
        .cell {
            padding: 0;
        }
    }
    
    .columns {
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap !important;
        gap: $spacing;
    }

    &__cta {
        margin-bottom: $spacing;
        position: relative;

        &::before {
            background-image: url('#{$libraryPath}/icons/src/triangle-down.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            height: 7rem;
            position: absolute;
            top: -1.5rem;
            left: -3.5rem;
            width: 7rem;
            z-index: -1;
        }
    }

    @include media ($screen-sm) {
        .columns {
            width: calc(66.67% - #{$spacing});
        }

        .contain {
            display: flex;
            gap: $spacing;
            justify-content: space-between;
        }

        &__cta {
            margin-bottom: 0;
            width: calc(33.33% - #{$spacing});

            &::before {
                height: 9rem;
                top: -2rem;
                left: -4.5rem;
                width: 9rem;
            }
        }
        
        &.swapped {
            .three-columns-with-cta__cta {
                order: 1;
            }
        }
    }
}