.statistics {

	&__left {
		padding: $spacing;
	}

	&__right {
		
		&-content {

			h2:first-child {
				border-bottom: 3px solid $teal;
				display: inline-block;
				font-size: 1.25rem;
				padding-bottom: .75rem;
				text-transform: none;
			}
		}
	}

	&__column {
		padding: $spacing;
		position: relative;

		.h1 {
			line-height: 1;
			margin-bottom: .5rem;

			&.has-plus {

				&::after {
					content: '+';
				}
			}
		}

		&.in-view {
			
			.statistics__background {opacity: 1;}
		}
	}

	&__background {
		color: rgba($white, .05);
		font-size: 69vw;
		line-height: 1;
		// opacity: 0;
		position: absolute;
			bottom: -25%;
			right: -22%;
		// transition: $animationDuration*3 opacity;
	}

	@include media($screen-sm-max, 'max') {

		&__left {
			margin: 0 -#{$spacing} 3rem;
		}

		&__right {

			&-content {
				margin-bottom: 1.5rem;
			}
		}

		&__column {
			padding-left: 0;
			padding-right: 0;

			.h1 {font-size: 18vw;}
		}
	}

	@include media($screen-sm) {

		&__left {
			display: flex;
				flex-direction: column;
				justify-content: center;
			padding-bottom: $spacing*2;
			padding-top: $spacing*2;
			position: relative;
			width: 35%;

			&::before {
				background: $blue;
				height: 100%;
				content: '';
				position: absolute;
					right: 100%;
					top: 0;
				width: 100vw;
			}
		}

		&__right {
			padding-left: 3rem;
			padding-top: 1rem;
			width: 65%;
		}

		&__column {
			// margin: 0 .5rem;
			width: 33.33%;
		}

		&__background {
			bottom: -17%;
			right: -35%;
			font-size: 15rem;
		}
	}

	@include media($screen-md) {

		&__column {
			padding: 3rem;

			.h1 {
				font-size: 4.5rem;

				&.has-plus {
					display: flex;
						align-items: center;

					&::after {
						font-size: 3rem;
					}
				}
			}
		}

		&__one,
		&__two {width: 30%;}
		&__three {width: 40%;}

		&__background {
			bottom: -20%;
			font-size: 20rem;
			right: -25%;
		}
	}
}