.clustered-cta {

	&__left {
		margin-bottom: $spacing*2;

		&-image {

		}
	}

	&__icon {
		min-width: 2.5rem;
		width: 2.5rem;

		img {
			display: block;
			width: 100%;
		}
	}

	&__right {

		&-content {
			margin-bottom: $spacing*2;
			
			ul {
				list-style: none;
				padding: 0;

				li {
					font-weight: bold;
					margin-bottom: .5rem;
					padding-left: 2.75rem;
					position: relative;

					&::before {
						border-top: 4px solid $teal;
						content: '';
						position: absolute;
							top: .6rem;
							left: 0;
						width: 2rem;
					}
				}
			}
		}

		&-top {
			margin-bottom: 1rem;

			a {
				line-height: 1.3;
				text-decoration: none;

				&:hover,
				&:focus,
				&:active {text-decoration: underline;}
			}

			strong {line-height: 1;}
		}
	}

	&__cta {
		margin-bottom: $spacing;
		margin-right: $spacing;

		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	&__description { font-size: .875rem; }

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			width: 50%;
		}

		&__right {
			align-self: center;
			padding-left: $spacing*2;
			width: 50%;
		}

		&__cta {
			flex: 1;
		}

		&.reverse {
			.contain {flex-direction: row-reverse;}

			.clustered-cta__right {
				padding: 0 4.5rem 0 0;
			}
		}
	}

	@include media($screen-md) {

		&__right {
			padding-left: $spacing*3;
		}
	}
}