/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.contact-cta {
  background-color: #2ABEC5; }
  .contact-cta__image img {
    border-radius: 50%;
    height: 15rem;
    max-width: none;
    object-fit: cover;
    object-position: left;
    width: 15rem; }
  .contact-cta__main {
    margin: 0 auto;
    max-width: 34rem;
    text-align: center; }
    .contact-cta__main h3, .contact-cta__main p {
      color: #ffffff; }
    .contact-cta__main .button.ghost {
      border-color: #ffffff;
      color: #ffffff; }
      .contact-cta__main .button.ghost:active, .contact-cta__main .button.ghost:focus, .contact-cta__main .button.ghost:hover {
        background-color: #ffffff;
        color: #2ABEC5; }
    .contact-cta__main .button.black-text {
      border-color: #000000; }
  .contact-cta__wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 0; }
  @media screen and (min-width: 48em) {
    .contact-cta__wrapper {
      padding: 3rem; } }
  @media screen and (min-width: 62em) {
    .contact-cta__main {
      text-align: start; }
      .contact-cta__main h3, .contact-cta__main p {
        color: #000000; }
      .contact-cta__main .button.ghost {
        border-color: #000000;
        color: #000000; }
        .contact-cta__main .button.ghost:active, .contact-cta__main .button.ghost:focus, .contact-cta__main .button.ghost:hover {
          background-color: #000000; }
    .contact-cta__wrapper {
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 4.5rem; }
    .contact-cta .button.ghost {
      font-size: 1.125rem;
      padding: 1rem 3rem; } }

.clustered-cta__left {
  margin-bottom: 3rem; }

.clustered-cta__icon {
  min-width: 2.5rem;
  width: 2.5rem; }
  .clustered-cta__icon img {
    display: block;
    width: 100%; }

.clustered-cta__right-content {
  margin-bottom: 3rem; }
  .clustered-cta__right-content ul {
    list-style: none;
    padding: 0; }
    .clustered-cta__right-content ul li {
      font-weight: bold;
      margin-bottom: .5rem;
      padding-left: 2.75rem;
      position: relative; }
      .clustered-cta__right-content ul li::before {
        border-top: 4px solid #2ABEC5;
        content: '';
        position: absolute;
        top: .6rem;
        left: 0;
        width: 2rem; }

.clustered-cta__right-top {
  margin-bottom: 1rem; }
  .clustered-cta__right-top a {
    line-height: 1.3;
    text-decoration: none; }
    .clustered-cta__right-top a:hover, .clustered-cta__right-top a:focus, .clustered-cta__right-top a:active {
      text-decoration: underline; }
  .clustered-cta__right-top strong {
    line-height: 1; }

.clustered-cta__cta {
  margin-bottom: 1.5rem;
  margin-right: 1.5rem; }
  .clustered-cta__cta:last-child {
    margin-bottom: 0;
    margin-right: 0; }

.clustered-cta__description {
  font-size: .875rem; }

@media screen and (min-width: 48em) {
  .clustered-cta__left {
    margin-bottom: 0;
    width: 50%; }
  .clustered-cta__right {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding-left: 3rem;
    width: 50%; }
  .clustered-cta__cta {
    -ms-flex: 1;
    flex: 1; }
  .clustered-cta.reverse .contain {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .clustered-cta.reverse .clustered-cta__right {
    padding: 0 4.5rem 0 0; } }

@media screen and (min-width: 62em) {
  .clustered-cta__right {
    padding-left: 4.5rem; } }

.news-feed__heading {
  margin-bottom: 1.5rem; }

.news-feed__list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 2rem; }
  .news-feed__list-item:last-child {
    margin-bottom: 0; }
  .news-feed__list-item h3.h6 {
    font-size: 1.125rem;
    margin-bottom: 1rem; }

.news-feed__featured-wrapper {
  margin-bottom: 1.5rem; }

.news-feed__featured-image {
  background: #ffffff;
  height: 0;
  overflow: hidden;
  padding-bottom: 60%;
  position: relative; }
  .news-feed__featured-image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .news-feed__featured-image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.news-feed__featured-text {
  background: #ffffff;
  padding: 1.5rem; }
  .news-feed__featured-text h3.h5 {
    font-size: 1.25rem; }

.news-feed__featured-category {
  background: #C2D1D9;
  border-radius: .25rem;
  display: inline-block;
  margin-bottom: 1rem;
  padding: .375rem 1rem .3rem; }

.layout.news-feed .news-feed__link {
  background: #ffffff;
  border-radius: 1rem;
  display: block;
  padding: 1.5rem; }
  .layout.news-feed .news-feed__link:after {
    right: 1.5rem !important; }

@media screen and (min-width: 48em) {
  .news-feed__heading {
    margin-bottom: 3rem; }
  .news-feed__list {
    width: 50%; }
    .news-feed__list-item {
      background-color: #ffffff;
      margin-bottom: 1.5rem; }
    .news-feed__list-text {
      padding: 1.5rem;
      width: 55%; }
      .news-feed__list-text h3.h6 {
        font-size: 1.125rem; }
    .news-feed__list-image {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      background-color: white;
      overflow: hidden;
      position: relative;
      width: calc(45% - 1.5rem); }
      .news-feed__list-image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .news-feed__list-image img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
  .news-feed__featured-wrapper {
    margin-bottom: 0;
    padding-left: 3rem;
    width: calc(50% - 3rem); }
  .news-feed__featured-text h3.h5 {
    font-size: 1.5625rem; } }

.partners {
  background-color: #e9e8e9;
  content-visibility: auto;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 3rem;
  padding-top: 3rem; }
  .partners__heading {
    max-width: 47rem;
    text-align: center; }
    .partners__heading p {
      font-size: 1.125rem; }
      @media screen and (min-width: 48em) {
        .partners__heading p {
          font-size: 1.25rem; } }
  .partners__heading {
    margin: 0 auto 3rem; }
  .partners__strip {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
    @media screen and (max-width: 61.999em) {
      .partners__strip {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  .partners__image {
    padding: 1rem; }
    .partners__image img {
      max-height: 50px;
      width: auto; }
    @media screen and (min-width: 48em) {
      .partners__image img {
        max-height: 75px; } }
    @media screen and (min-width: 62em) {
      .partners__image img {
        max-height: 100px; } }

/**
 * Programs Slider ACF Layout Styles
 * DBS>Interactive
 */
.programs-slider button.dot {
  border-radius: 100%;
  width: .75em;
  height: .75em;
  padding: 0;
  margin: 0em .75em;
  opacity: 1;
  background: transparent;
  transform: scale(1);
  border: 0.1em solid #ffffff; }
  .programs-slider button.dot:focus, .programs-slider button.dot:hover, .programs-slider button.dot.is-selected {
    background-color: #000000;
    border-color: #ffffff;
    transform: scale(1.5);
    transition: transform .25s linear; }

.programs-slider .contain {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4.5rem; }

.programs-slider .flickity-viewport {
  transition: height 0.25s; }

.programs-slider .flickity-button {
  height: 2.5rem;
  width: 2.5rem;
  transform: none;
  top: 100%; }
  .programs-slider .flickity-button svg path {
    fill: #546653; }
  .programs-slider .flickity-button.next {
    right: 0; }
  .programs-slider .flickity-button.previous {
    left: auto;
    right: 4rem; }

.programs-slider__cell {
  height: 100%;
  width: 100%; }
  .programs-slider__cell a {
    font-family: "Myriad Pro Condensed", "Verdana", "Helvetica", sans-serif;
    text-decoration: none; }
  .programs-slider__cell img {
    display: block; }
  .programs-slider__cell-upper {
    max-height: 100%; }
    .programs-slider__cell-upper img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .programs-slider__cell-upper img {
          height: 100%;
          object-fit: "cover";
          object-position: "center"; } }
  .programs-slider__cell:not(:last-child) {
    margin-bottom: 1.5rem; }

.programs-slider__element {
  box-shadow: none !important; }

.programs-slider__left-content {
  position: relative;
  width: 100%;
  z-index: 2; }
  .programs-slider__left-content h2 {
    font-family: "Myriad Pro", "Verdana", "Helvetica", sans-serif;
    position: relative; }
    .programs-slider__left-content h2::before {
      background-image: url(/wp-content/themes/leadershiplou/library/icons/src/triangle-down.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      height: 7rem;
      position: absolute;
      top: -1.5rem;
      left: -3.5rem;
      width: 7rem;
      z-index: -1; }

@media screen and (min-width: 48em) {
  .programs-slider__cell {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0.75rem !important;
    width: calc(45% - 0.75rem); }
  .programs-slider__element {
    position: relative; }
    .programs-slider__element .flickity-viewport {
      overflow: visible; }
    .programs-slider__element::after {
      content: 'flickity';
      opacity: 0; }
    .programs-slider__element::before {
      background: #ffffff;
      content: '';
      height: 100%;
      position: absolute;
      right: calc(100% + .75rem);
      top: 0;
      width: 100vw;
      z-index: 1; } }

@media screen and (min-width: 62em) {
  .programs-slider .contain {
    -ms-flex-direction: row;
    flex-direction: row; }
  .programs-slider__left-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30%; }
    .programs-slider__left-content h2::before {
      height: 9rem;
      top: -2rem;
      left: -4.5rem;
      width: 9rem; }
  .programs-slider__element {
    width: 70%; } }

/**
 * Quick Links
 */
.quick-links select {
  appearance: none;
  background-color: #849784;
  border-radius: 0.25rem;
  color: #ffffff;
  font-family: "Myriad Pro", "Verdana", "Helvetica", sans-serif;
  font-size: .9rem;
  font-weight: 600;
  padding: 0.75rem 3rem 0.75rem 0.75rem; }

.quick-links__select-wrapper {
  margin: 0 auto;
  position: relative;
  width: fit-content; }

.quick-links__select-wrapper::after {
  background-image: url("/wp-content/themes/leadershiplou-child/library/icons/src/arrow-down-white.svg");
  bottom: .5rem;
  content: '';
  height: 2rem;
  position: absolute;
  right: .5rem;
  width: 2rem; }

.quick-links__container {
  text-align: center; }

.quick-links__heading {
  color: #ffffff;
  font-family: "Myriad Pro", "Verdana", "Helvetica", sans-serif;
  text-transform: none; }

@media screen and (min-width: 30em) {
  .quick-links select {
    font-size: 1.25rem;
    padding: 0.75rem 3rem 0.75rem 1.5rem; } }

/**
 * Testimonial Slider ACF Layout Styles
 * DBS>Interactive
 */
.testimonial-slider__cell {
  width: 100%; }
  .testimonial-slider__cell-text-author-name {
    font-weight: 600; }
  .testimonial-slider__cell-text-author-title {
    font-size: .9rem; }

.testimonial-slider__element {
  box-shadow: none !important;
  position: relative; }

.testimonial-slider .flickity-viewport {
  transition: height 0.25s; }

.testimonial-slider .flickity-button {
  z-index: 10; }

.testimonial-slider .flickity-page-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  bottom: 0; }

.testimonial-slider button.dot {
  border-radius: 100%;
  width: .75em;
  height: .75em;
  padding: 0;
  margin: 0em .75em;
  opacity: 1;
  background: transparent;
  transform: scale(1);
  border: 0.1em solid #ffffff; }
  .testimonial-slider button.dot:focus, .testimonial-slider button.dot:hover, .testimonial-slider button.dot.is-selected {
    background-color: #000000;
    border-color: #ffffff;
    transform: scale(1.5);
    transition: transform .25s linear; }

@media screen and (min-width: 48em) {
  .testimonial-slider__cell {
    margin-right: 1.5rem; } }

.testimonial-slider.bg-blue .testimonial-slider__cell-text {
  background-color: rgba(255, 255, 255, 0.05); }

.testimonial-slider.bg-dark .testimonial-slider__cell-text {
  background-color: rgba(132, 151, 132, 0.4); }

.testimonial-slider.one-up .testimonial-slider__cell, .testimonial-slider.two-up .testimonial-slider__cell {
  margin-right: 1.5rem;
  position: relative;
  text-align: center; }
  .testimonial-slider.one-up .testimonial-slider__cell-text, .testimonial-slider.two-up .testimonial-slider__cell-text {
    padding: 1.5rem;
    position: relative;
    z-index: 0; }

.testimonial-slider.one-up .testimonial-slider__heading, .testimonial-slider.two-up .testimonial-slider__heading {
  padding: 0 1.5rem 3rem; }

.testimonial-slider.one-up .testimonial-slider__image, .testimonial-slider.two-up .testimonial-slider__image {
  height: 8rem;
  margin: 0 auto 1.5rem;
  width: 8rem;
  z-index: 1; }
  .testimonial-slider.one-up .testimonial-slider__image img, .testimonial-slider.two-up .testimonial-slider__image img {
    border-radius: 50%; }

.testimonial-slider.one-up .testimonial-slider__cell-text {
  margin: 0 auto;
  max-width: 52rem; }

@media screen and (max-width: 61.999em) {
  .testimonial-slider .flickity-button {
    background: transparent; }
    .testimonial-slider .flickity-button-icon {
      fill: #e9e8e9; }
  .testimonial-slider .flickity-prev-next-button {
    bottom: 0;
    height: 50px;
    width: 50px;
    top: unset; }
    .testimonial-slider .flickity-prev-next-button.previous {
      left: 14%; }
    .testimonial-slider .flickity-prev-next-button.next {
      right: 14%; } }

@media screen and (min-width: 62em) {
  .testimonial-slider.two-up .flickity-prev-next-button {
    top: 50%; }
  .testimonial-slider.two-up .testimonial-slider__cell {
    margin-right: 1.5rem;
    width: calc(50% - 0.75rem); }
    .testimonial-slider.two-up .testimonial-slider__cell-text {
      padding: 1.5rem 1.5rem 1.5rem 7rem;
      display: -ms-flexbox;
      display: flex;
      gap: 1.5rem; }
      .testimonial-slider.two-up .testimonial-slider__cell-text-quote {
        margin-bottom: 0;
        -ms-flex-order: 1;
        order: 1;
        width: 70%; }
      .testimonial-slider.two-up .testimonial-slider__cell-text-author {
        width: 30%; }
  .testimonial-slider.two-up .testimonial-slider__element {
    padding: 0 5rem; }
    .testimonial-slider.two-up .testimonial-slider__element::before {
      background-color: #0c3f5e;
      clip-path: inset(0% 87% 0% 0%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: -10%;
      width: 120%;
      z-index: 5; }
    .testimonial-slider.two-up .testimonial-slider__element::after {
      background-color: #0c3f5e;
      clip-path: inset(0% 0% 0% 87%);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: -10%;
      width: 120%;
      z-index: 5; }
  .testimonial-slider.two-up .testimonial-slider__image {
    height: 4rem;
    left: 1.5rem;
    top: 1.5rem;
    transform: none;
    width: 4rem; } }

/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */
.three-columns-with-cta .column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc(33.33% - 0.75rem); }
  .three-columns-with-cta .column .cell {
    padding: 0; }

.three-columns-with-cta .columns {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  gap: 1.5rem; }

.three-columns-with-cta__cta {
  margin-bottom: 1.5rem;
  position: relative; }
  .three-columns-with-cta__cta::before {
    background-image: url("/wp-content/themes/leadershiplou-child/library/icons/src/triangle-down.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    height: 7rem;
    position: absolute;
    top: -1.5rem;
    left: -3.5rem;
    width: 7rem;
    z-index: -1; }

@media screen and (min-width: 48em) {
  .three-columns-with-cta .columns {
    width: calc(66.67% - 1.5rem); }
  .three-columns-with-cta .contain {
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .three-columns-with-cta__cta {
    margin-bottom: 0;
    width: calc(33.33% - 1.5rem); }
    .three-columns-with-cta__cta::before {
      height: 9rem;
      top: -2rem;
      left: -4.5rem;
      width: 9rem; }
  .three-columns-with-cta.swapped .three-columns-with-cta__cta {
    -ms-flex-order: 1;
    order: 1; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  position: relative; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.bg-dark, .two-columns__left.bg-light, .two-columns__right.bg-dark, .two-columns__right.bg-light {
    padding: 1.5rem; }

.two-columns .blue-accent:after {
  background: #0c3f5e;
  content: '';
  height: 85%;
  position: absolute;
  top: -1.5rem;
  width: calc(100% + 1.5rem);
  z-index: -1; }

.two-columns .blue-accent.two-columns__left {
  margin-right: 1.5rem !important; }
  .two-columns .blue-accent.two-columns__left:after {
    border-radius: 0 1.5rem 1.5rem 0;
    right: -1.5rem; }
  .two-columns .blue-accent.two-columns__left img {
    border-radius: 0 1.5rem 1.5rem 0; }

.two-columns .blue-accent.two-columns__right {
  margin-right: 1.5rem !important; }
  .two-columns .blue-accent.two-columns__right:after {
    border-radius: 1.5rem 0 0 1.5rem;
    right: -1.5rem; }
  .two-columns .blue-accent.two-columns__right img {
    border-radius: 1.5rem 0 0 1.5rem; }

.two-columns.gradient-with-border {
  background: linear-gradient(#637962, #333532);
  border-top: 13px solid #2ABEC5;
  color: #ffffff;
  padding: 3rem 0; }
  .two-columns.gradient-with-border h1, .two-columns.gradient-with-border h2, .two-columns.gradient-with-border h3, .two-columns.gradient-with-border h4, .two-columns.gradient-with-border h5, .two-columns.gradient-with-border h6 {
    color: #ffffff; }
  .two-columns.gradient-with-border h3 {
    font-family: "Myriad Pro", "Verdana", "Helvetica", sans-serif; }
  .two-columns.gradient-with-border .contain {
    -ms-flex-align: center;
    align-items: center; }
  @media screen and (max-width: 47.999em) {
    .two-columns.gradient-with-border .two-columns__left {
      margin-bottom: 3rem; }
    .two-columns.gradient-with-border .two-columns__right {
      text-align: center; } }
  @media screen and (min-width: 48em) {
    .two-columns.gradient-with-border {
      padding: 6rem 0; }
      .two-columns.gradient-with-border .two-columns__left {
        width: calc(55% - 1.5rem); }
      .two-columns.gradient-with-border .two-columns__right {
        width: calc(45% - 1.5rem); } }

@media screen and (min-width: 62em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: 60%; }
  .two-columns.two-thirds-right .two-columns__left {
    padding-right: 1.5rem;
    width: 40%; } }

@media screen and (min-width: 62em) {
  .two-columns.two-thirds-left .two-columns__right {
    padding-left: 1.5rem;
    width: 40%; }
  .two-columns.two-thirds-left .two-columns__left {
    width: 60%; } }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; }
  .two-columns.full-bleed .has-img {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .two-columns .blue-accent {
    margin-top: 3rem !important; }
  .two-columns + .full-bleed {
    margin-top: 1.5rem !important; }
  .two-columns .two-columns__left.has-img {
    margin-bottom: 0;
    margin-top: 1.5rem;
    -ms-flex-order: 2;
    order: 2; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; }
  .two-columns.full-bleed {
    position: relative; }
    .two-columns.full-bleed .two-columns__left:not(.has-img),
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      padding: 3rem 0; }
    .two-columns.full-bleed .two-columns__left:not(.has-img) {
      margin-right: auto; }
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      margin-left: auto; }
    .two-columns.full-bleed .has-img {
      height: 100%;
      position: absolute;
      top: 0;
      width: 50%; }
      .two-columns.full-bleed .has-img.two-columns__left {
        left: 0; }
      .two-columns.full-bleed .has-img.two-columns__right {
        right: 0; }
      .two-columns.full-bleed .has-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .two-columns.full-bleed .has-img img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
  .two-columns .blue-accent.two-columns__left, .two-columns .blue-accent.two-columns__right {
    width: calc(50% - 3rem); } }

@media screen and (min-width: 62em) {
  .two-columns.full-bleed .two-columns__left:not(.has-img),
  .two-columns.full-bleed .two-columns__right:not(.has-img) {
    padding: 4.5rem 0; } }

.statistics__left {
  padding: 1.5rem; }

.statistics__right-content h2:first-child {
  border-bottom: 3px solid #2ABEC5;
  display: inline-block;
  font-size: 1.25rem;
  padding-bottom: .75rem;
  text-transform: none; }

.statistics__column {
  padding: 1.5rem;
  position: relative; }
  .statistics__column .h1 {
    line-height: 1;
    margin-bottom: .5rem; }
    .statistics__column .h1.has-plus::after {
      content: '+'; }
  .statistics__column.in-view .statistics__background {
    opacity: 1; }

.statistics__background {
  color: rgba(255, 255, 255, 0.05);
  font-size: 69vw;
  line-height: 1;
  position: absolute;
  bottom: -25%;
  right: -22%; }

@media screen and (max-width: 47.999em) {
  .statistics__left {
    margin: 0 -1.5rem 3rem; }
  .statistics__right-content {
    margin-bottom: 1.5rem; }
  .statistics__column {
    padding-left: 0;
    padding-right: 0; }
    .statistics__column .h1 {
      font-size: 18vw; } }

@media screen and (min-width: 48em) {
  .statistics__left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 3rem;
    position: relative;
    width: 35%; }
    .statistics__left::before {
      background: #0c3f5e;
      height: 100%;
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      width: 100vw; }
  .statistics__right {
    padding-left: 3rem;
    padding-top: 1rem;
    width: 65%; }
  .statistics__column {
    width: 33.33%; }
  .statistics__background {
    bottom: -17%;
    right: -35%;
    font-size: 15rem; } }

@media screen and (min-width: 62em) {
  .statistics__column {
    padding: 3rem; }
    .statistics__column .h1 {
      font-size: 4.5rem; }
      .statistics__column .h1.has-plus {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center; }
        .statistics__column .h1.has-plus::after {
          font-size: 3rem; }
  .statistics__one, .statistics__two {
    width: 30%; }
  .statistics__three {
    width: 40%; }
  .statistics__background {
    bottom: -20%;
    font-size: 20rem;
    right: -25%; } }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.64em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media (min-width: 48em) {
      #dbs-chev strong {
        font-size: 0.8em; } }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #000000;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  overflow: hidden;
  clear: both;
  margin-top: auto; }
  .site-footer__container {
    padding: 3rem 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    text-decoration: none; }
    .site-footer a:hover {
      text-decoration: underline; }
  .site-footer nav {
    font-family: "Myriad Pro", "Verdana", "Helvetica", sans-serif; }
  .site-footer .site-footer__logo a {
    display: block; }
  .site-footer .site-footer__logo svg {
    display: block;
    max-width: 13em;
    padding-bottom: 1.5em; }
  .site-footer__business ul, .site-footer__business li {
    display: block; }
  .site-footer__navigation .footer-menu__item a {
    display: block;
    font-weight: 400;
    padding: .25rem 0; }
  .site-footer__navigation .footer-menu__item.toplevel {
    margin-bottom: 1rem; }
    .site-footer__navigation .footer-menu__item.toplevel > a {
      color: #ffffff;
      font-weight: 700;
      letter-spacing: 1px; }
  .site-footer__navigation li {
    display: block; }
  .site-footer .social-media {
    margin: 1.5em 0 0; }
    .site-footer .social-media__link {
      display: inline-block;
      margin-right: .75rem; }
    .site-footer .social-media a {
      display: block;
      width: 1.35em; }
    .site-footer .social-media svg path {
      fill: #ffffff; }
  .site-footer__credits .contain {
    padding: 1.5rem; }
  .site-footer__credits .legal-footer {
    display: inline-block; }
    .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
      display: inline-block; }
    .site-footer__credits .legal-footer a {
      font-weight: 400; }
    .site-footer__credits .legal-footer .legal-menu__item:not(:last-child) {
      border-right: 1px solid #ffffff;
      margin-right: .5rem;
      padding-right: .75rem; }
  @media screen and (max-width: 61.999em) {
    .site-footer__logo svg {
      margin: 0 auto;
      padding-bottom: 3rem; }
    .site-footer__content {
      margin-bottom: 3rem; }
    .site-footer__navigation {
      margin: -1.5rem; }
      .site-footer__navigation .footer-menu__submenu {
        display: none; }
      .site-footer__navigation .footer-menu__item--parent {
        position: relative; }
        .site-footer__navigation .footer-menu__item--parent.open .footer-menu__submenu {
          display: block; }
      .site-footer__navigation .footer-menu__item.toplevel {
        border-bottom: 1px solid #ffffff;
        margin-bottom: 0; }
        .site-footer__navigation .footer-menu__item.toplevel a {
          padding: 1rem 1.5rem; }
      .site-footer__navigation .footer-menu__item:first-child.toplevel {
        border-top: 1px solid #ffffff; }
      .site-footer__navigation .submenu-toggle svg path {
        fill: #ffffff; }
    .site-footer__credits .contain div {
      margin-top: 1.5rem;
      text-align: center; } }
  @media screen and (min-width: 62em) {
    .site-footer__content {
      padding-right: 4.5rem; }
      .site-footer__content .site-footer__business li:first-child {
        margin-bottom: 3rem; }
    .site-footer__navigation {
      -ms-flex: 1;
      flex: 1; }
      .site-footer__navigation > ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; }
      .site-footer__navigation .footer-menu__item a:after {
        background: #ffffff;
        content: '';
        display: block;
        height: 1px;
        position: relative;
        top: 3px;
        transform: scaleX(0);
        transform-origin: 100% 0;
        transition: transform .25s ease-in-out; }
      .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
        text-decoration: none; }
        .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
          transform: scaleX(1);
          transform-origin: 0 100%; }
      .site-footer__navigation .footer-menu__item.toplevel {
        padding-right: 1.5rem; }
        .site-footer__navigation .footer-menu__item.toplevel > a {
          text-transform: uppercase; }
    .site-footer__credits {
      border-top: 1px solid #ffffff; }
      .site-footer__credits .contain {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-pack: justify;
        justify-content: space-between; } }
  @media screen and (min-width: 75em) {
    .site-footer__content {
      padding-right: 6rem; } }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
