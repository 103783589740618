.contact-cta {
    background-color: $teal;
    
    &__image {
        img {
            border-radius: 50%;
            height: 15rem;
            max-width: none;
            object-fit: cover;
            
            // This is for the default image to display properly and should not
            // be an issue for override images, as long as they are square.
            object-position: left;
            
            width: 15rem;
        }
    }
    
    &__main {
        margin: 0 auto;
        max-width: 34rem;
        text-align: center;
        
        h3, p {
            color: $white;
        }
        
        .button.ghost {
            border-color: $white;
            color: $white;
            
            &:active,
            &:focus,
            &:hover {
                background-color: $white;
                color: $teal;
            }
        }

        .button.black-text {
            border-color: $black;
        }
    }
    
	&__wrapper {
        display: flex;
            align-items: center;
            flex-direction: column;
            gap: $spacing;
		padding: #{$spacing * 2} 0;
	}

	@include media($screen-sm) {
		&__wrapper {
			padding: #{$spacing * 2};
		}
	}

	@include media($screen-md) {
        &__main {
            text-align: start;

            h3, p {
                color: $black;
            }
            
            .button.ghost {
                border-color: $black;
                color: $black;
                
                &:active,
                &:focus,
                &:hover {
                    background-color: $black;
                }
            }
        }
        
		&__wrapper {
            flex-direction: row;
			padding: #{$spacing * 3};
		}

		.button.ghost {
			font-size: 1.125rem;
			padding: 1rem 3rem;
		}
	}
}